@import '../../style/variables.scss';

.navigation {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: background 200ms ease, padding 200ms ease, box-shadow 100ms ease;

  &.scroll {
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, .1);
    background: $mono-8;
  }
}

.navigation__list {
  flex-grow: 1;
  text-align: right;

  li {
    vertical-align: middle;
  }
}

.navigation__item {
  a {
    color: inherit;
  }
}

.navigation__action {
  .btn {
    padding: 8px 25px;
    font-size: inherit;
  }
}

@media all and (max-width: 639px) {
  .navigation {
    width: 88%;
    padding: 30px 6% 15px;
    z-index: 3;

    &.scroll {
      padding: 15px 6%;
    }
  }

  .navigation__logo {
    margin: 0 0 -3px;
    height: 28px;
  }

  .navigation__toggler {
    position: absolute;
    right: 6%;
    padding: 5px;
    font-size: 20px;
    color: $mono-4;
  }

  .navigation__list {
    display: none;

    &.open {
      display: initial;
      position: fixed;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      padding: 60px 25px 0;
      background-color: $accent-1;
      font-size: $typo-subtitle;
      text-align: left;
      color: $mono-8;
      box-shadow: -30vh 0 0 rgba(17, 21, 23, .8);
    }
  }

  .navigation__item {
    margin: 0 0 20px;

    a {
      &.on {
        font-weight: 400;
      }
    }
  }

  .navigation__action {
    margin: 30px 0 0;
    border-top: 0.75px solid $mono-8;

    .btn {
      margin: 25px 0 0;
      padding: 0;
      background: none;
      font-weight: 300;
      color: inherit;
    }
  }
}

@media all and (min-width: 640px) {
  .navigation {
    width: 90%;
    padding: 40px 5% 20px;
    z-index: 1;

    &.scroll {
      padding: 15px 5%;

      .navigation__action {
        .btn {
          background-color: $accent-1;
          color: $mono-8;

          &:hover, &:focus {
            background-color: $depth-accent-1;
          }
        }
      }
    }
  }

  .navigation__logo {
    height: 30px;
  }

  .navigation__toggler {
    display: none;
  }

  .navigation__list {
    font-size: $typo-description;

    li {
      display: inline-block;
      margin-left: 40px;
    }
  }

  .navigation__item {
    color: $mono-4;

    a {
      &:hover, &:focus {
        color: $mono-3;
      }

      &.on {
        color: $mono-3;
        font-weight: 400;
      }
    }
  }
}
