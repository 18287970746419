$accent-1: #0F75FD;
$accent-2: #10D497;

$depth-accent-1: #0D60EA;
$depth-mono-6: #EAEBEC;

$mono-1: #111518;
$mono-2: #232A30;
$mono-3: #414243;
$mono-4: #898D96;
$mono-5: #D2D3D6;
$mono-6: #F1F2F3;
$mono-7: #F7F8F9;
$mono-8: #FFFFFF;

$typo-title: 1.5rem;
$typo-subtitle: 1.125rem;
$typo-body: 0.875rem;
$typo-description: 0.825rem;
$typo-annotation: 0.7rem;


$typo-mobile-title: 1.35rem;
$typo-mobile-subtitle: 1rem;
$typo-mobile-body: 0.9rem;
$typo-mobile-description: 0.8rem;
$typo-mobile-annotation: 0.6rem;
