@import '../../style/variables.scss';

.btn {
  display: inline-block;
  padding: 8px 10px;
  background-color: $mono-6;
  font-weight: 400;
  color: $mono-4;
  transition: background-color 200ms ease;
  line-height: normal;
  cursor: pointer;
  box-sizing: border-box;

  &:hover, &:focus {
    background-color: $depth-mono-6;
  }

  .material-icons {
    display: inline-block;
    margin: -2px 0 2px;
  }
}

.btn-action {
  background-color: $accent-1;
  color: $mono-8;

  &:hover, &:focus {
    background-color: $depth-accent-1;
  }
}

.btn-disabled {
  background-color: $mono-5;
  color: $mono-4;
  cursor: not-allowed;

  &:hover, &:focus {
    background-color: $mono-5;
  }
}

@media all and (max-width: 639px) {
  .btn {
    font-size: $typo-mobile-description;
  }
}

@media all and (min-width: 640px) {
  .btn {
    font-size: $typo-description;
  }
}
