@import '../../style/variables.scss';

.footer {
  display: flex;
  background-color: $mono-7;
  color: $mono-3;
}

.footer__outlink {
  a {
    color: inherit;
  }

  ul, li {
    display: inline-block;
    vertical-align: middle;
  }
}

.footer__contact {
  img {
    opacity: .45;

    &:hover {
      opacity: .75;
    }
  }
}

@media all and (max-width: 639px) {
  .footer {
    flex-direction: column-reverse;
    padding: 25px 6% 20px;
    text-align: center;
  }

  .footer__copyright {
    margin: 0 0 10px;
    font-size: $typo-annotation;
    color: $mono-4;
  }

  .footer__outlink {
    display: flex;
    flex-direction: column-reverse;
  }

  .footer__service-terms {
    li {
      margin: 0 5px 10px;
      font-size: $typo-mobile-description;

      &:first-child {
        &:after {
          content: '';
          display: inline-block;
          margin-left: 10px;
          border-right: 1px solid $mono-5;
          height: 10px;
        }
      }
    }
  }

  .footer__contact {
    img {
      height: 18px;
      margin: 0 12.5px 10px;
    }
  }
}

@media all and (min-width: 640px) {
  .footer {
    align-items: center;
    padding: 30px 5% 25px;
    font-size: $typo-description;
  }

  .footer__outlink {
    flex-grow: 1;
    text-align: right;

    a {
      &:hover {
        color: $mono-4;
      }
    }

    li {
      margin-left: 20px;
    }
  }

  .footer__service-terms {
    margin-right: 15px;
  }

  .footer__contact {
    img {
      height: 16px;
      margin-bottom: -2px;
    }
  }
}
