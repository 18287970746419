@import './variables.scss';

main {
  min-height: 100vh;
  text-align: center;
  box-sizing: border-box;
}

.area {
  text-align: left;
}

.area-border {
  border-top: 1px solid $mono-5;
}

.material-icons {
  display: inherit;
  vertical-align: middle;
  font-size: inherit;
  line-height: inherit;
}

.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background-color: $accent-1;
  z-index: 30;
}

.layout-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@media all and (max-width: 639px) {
  .area {
    padding: 0 6% 60px;
  }

  .area-border {
    width: 88%;
    margin: 0 auto 30px;
  }
}

@media all and (min-width: 640px) {
  .area {
    padding: 0 5% 80px;
    max-width: 1100px;
  }

  .area-border {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto 60px;
  }
}
