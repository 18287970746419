@import './variables.scss';

.wf-loading,
.wf-inactive {
  font-family: 'SpoqaHanSans', 'Noto Sans CJK', sans-serif;

  button,input,optgroup,select,textarea {
    font-family: 'SpoqaHanSans', 'Noto Sans CJK', sans-serif;
  }
}

.wf-active {
  font-family: 'Objectivity', 'Spoqa Han Sans';

  button,input,optgroup,select,textarea {
    font-family: 'Objectivity', 'Spoqa Han Sans';
  }
}

html {
  line-height: 1.4;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-weight: 300;
  // letter-spacing: -.01rem;
  color: $mono-1;
  font-size: 16px;
}

html,body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

article,aside,footer,header,nav,section {
  display: block
}

p {
  margin: 0;
  padding: 0;
}

figcaption,figure,main {
  display: block
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

pre {
  font-family: monospace,monospace;
  font-size: 1em
}

a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted
}

b,strong {
  font-weight: inherit;
  font-weight: 700
}

code,kbd,samp {
  font-family: monospace,monospace;
  font-size: 1em
}

dfn {
  font-style: italic
}

mark {
  background-color: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

audio,video {
  display: inline-block
}

audio:not([controls]) {
  display: none;
  height: 0
}

img {
  border-style: none
}

svg:not(:root) {
  overflow: hidden
}

button,input,optgroup,select,textarea {
  margin: 0;
  font-weight: 300;
  outline: none;
}

button {
  border: none;
  background: none
}

button,input {
  overflow: visible
}

button,select {
  text-transform: none
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0
}

button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

progress {
  display: inline-block;
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type="checkbox"],[type="radio"] {
  box-sizing: border-box;
  padding: 0
}

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {
  height: auto
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

details,menu {
  display: block
}

summary {
  display: list-item
}

canvas {
  display: inline-block
}

template {
  display: none
}

[hidden] {
  display: none
}

/*::-webkit-scrollbar {
    width: 7px;
    height: 8px
}

::-webkit-scrollbar-track {
    border-radius: 4px
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #d2d3d6;
}*/

::selection {
  background-color: #0e81ff;
  color: $mono-8;
}
