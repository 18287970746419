@import '../../style/variables.scss';

#live-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 5%;
  bottom: 35px;
  background-color: $accent-1;
  line-height: 0;
  box-shadow: 0px 6px 15px rgba(0,0,0,.2);
  transition: all 200ms cubic-bezier(.4,0,.2,1);
  z-index: 2;

  .material-icons {
    margin-top: 2px;
    margin-bottom: -2px;
    font-size: inherit;
    color: $mono-8;
  }

  &:hover, &:focus {
    margin-bottom: -5px;
    background-color: $depth-accent-1;
    box-shadow: 0px 2px 8px rgba(0,0,0,.35);
  }
}

@media all and (max-width: 639px) {
  #live-chat {
    width: 50px;
    height: 50px;
    font-size: 18px;
  }
}

@media all and (min-width: 640px) {
  #live-chat {
    width: 55px;
    height: 55px;
    font-size: 20px;
  }
}
